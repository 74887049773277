<!--
 * @Description: 财务综合管理 财务管理 支付对账 onroadPattern
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="支付渠道">
              <el-select v-model="searchForm.payMethod"
                         placeholder="请选择"
                         clearable>
                <el-option v-for="item in paymentOptions"
                           :key="item.value"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="账单日期">
              <el-date-picker v-model="orderTime"
                              type="daterange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              clearable>
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="onSubmit">查 询</el-button>
          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <el-button type="info"
                   class="tableTitleButton"
                   @click.native="exportFile">导出</el-button>
        <el-tabs v-model="activeName"
                 @tab-click="handlChangeTable"
                 style="width:90%">
          <el-tab-pane label="收费对账记录"
                       name="first"></el-tab-pane>
          <el-tab-pane label="退款对账记录"
                       name="second"></el-tab-pane>
        </el-tabs>
      </el-row>

      <el-row class="tableContent"
              v-if="activeName ==='first'">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :paginationConfig='paginationConfig'
                  :tableConfig='tableConfig'
                  @getList="getList">
          <template slot="isFirst">
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button @click="handleClick(scope.row)"
                             type="text"
                             size="medium">详情</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>

      <el-row class="tableContent"
              v-if="activeName ==='second'">
        <dt-table :tableColumnList="tableColumnListNew"
                  :data="tableListNew"
                  :paginationConfig='paginationConfigNew'
                  :tableConfig='tableConfig'
                  :map="listMap"
                  @getList="getList">
          <template slot="isFirst">
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button @click="handleClick(scope.row)"
                             type="text"
                             size="medium">详情</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import dtTable from '@/components/table.vue'
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      activeName: 'first',
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      paginationConfigNew: { layout: 'prev,pager,next,jumper', total: 0 },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      tableColumnList: [
        {
          prop: 'orderDate',
          label: '账单日期',
        },
        {
          prop: 'payMethodName',
          label: '支付渠道',
        },
        {
          prop: 'eqAmount',
          label: '对平金额(元)',
        },
        {
          prop: 'eqCount',
          label: '对平笔数',
        },
        {
          prop: 'ltAmount',
          label: '短款金额(元)',
        },
        {
          prop: 'ltCount',
          label: '短款笔数',
        },
        {
          prop: 'gtAmount',
          label: '长款金额（元）',
        },
        {
          prop: 'gtCount',
          label: '长款笔数',
        },
      ],
      tableColumnListNew: [
        {
          prop: 'orderDate',
          label: '账单日期',
        },
        {
          prop: 'payMethodName',
          label: '支付渠道',
        },
        {
          prop: 'eqAmount',
          label: '对平金额(元)',
        },
        {
          prop: 'eqCount',
          label: '对平笔数',
        },
        {
          prop: 'ltAmount',
          label: '短款金额(元)',
        },
        {
          prop: 'ltCount',
          label: '短款笔数',
        },
        {
          prop: 'gtAmount',
          label: '长款金额（元）',
        },
        {
          prop: 'gtCount',
          label: '长款笔数',
        },
      ],
      tableList: {
        list: [],
      },
      tableListNew: {
        list: [],
      },
      searchForm: {},
      pageNum: 1,
      pageSize: 15,
      //账单日期
      orderTime: [],
      //支付渠道下拉框
      paymentOptions: [],
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getPayMethod()
    this.queryTableList(0);
  },
  //方法集合
  methods: {
    // 导出
    exportFile () {
      // this.searchForm
      this.$axios.post(this.$downloadOrderBaseUrl + "checkBill/export", {}, {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        },
      }
      ).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "支付对账.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      })
    },
    // tabs切换效果
    handlChangeTable () {
      if (this.activeName === "first") {
        this.queryTableList(0)
      }
      else if (this.activeName === "second") {
        this.queryTableList(1)
      }
    },
    //初始化表格数据
    queryTableList (flag) {
      this.searchForm['pageNum'] = this.pageNum
      this.searchForm['pageSize'] = this.pageSize
      this.searchForm['checkType'] = flag
      console.log(this.searchForm);
      this.$onroadPattern.list(this.searchForm).then(res => {
        if (flag == 0) {
          res.resultEntity.list.forEach(item => {
            item.eqAmount = (item.eqAmount / 100).toFixed(2)
            item.ltAmount = (item.ltAmount / 100).toFixed(2)
            item.gtAmount = (item.gtAmount / 100).toFixed(2)
          });
          this.tableList.list = res.resultEntity.list
          this.paginationConfig.total = res.resultEntity.total
        }
        else if (flag == 1) {
          res.resultEntity.list.forEach(item => {
            item.eqAmount = (item.eqAmount / 100).toFixed(2)
            item.ltAmount = (item.ltAmount / 100).toFixed(2)
            item.gtAmount = (item.gtAmount / 100).toFixed(2)
          });
          this.tableListNew.list = res.resultEntity.list
          this.paginationConfigNew.total = res.resultEntity.total
        }
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.handlChangeTable()
    },
    //查询
    onSubmit () {
      this.pageNum = 1;
      if (this.orderTime) {
        this.searchForm.startTime = this.orderTime[0]
        this.searchForm.endTime = this.orderTime[1]
      }
      else {
        this.searchForm.startTime = ''
        this.searchForm.endTime = ''
      }
      this.handlChangeTable()
    },
    //重置
    resetForm () {
      this.orderTime = null
      this.searchForm = {}
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.activeName = "first"
      this.handlChangeTable()
    },
    //查看
    handleClick (row) {
      this.$router.push({ path: '/onroadPatternDetails', query: { parentId: row.id } })
    },
    //支付方式下拉菜单
    getPayMethod () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '58184EF53AA9411AB1A2F49E6DC3D223',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.paymentOptions = response.resultEntity
      })
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
// 最外层div
.mainbody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    // height: calc(100% - #{$topSelectHeight});
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.search_column1 {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1.5fr;
}
.search_column2 {
  margin-top: 20px;
}
</style>

